import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHomeLgAlt,
  faAddressCard,
  faInfo,
  faHandshakeAngle,
} from "@fortawesome/free-solid-svg-icons";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";

function FixedNav() {
  gsap.registerPlugin(useGSAP);
  const content = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useGSAP(
    () => {
      gsap.fromTo(
        ".fixednav",
        { opacity: 0, y: 0 },
        {
          opacity: 1,
          y: "-100px",
          ease: "power2.out",
          scrollTrigger: {
            trigger: ".fixednav",
            start: "top 85%",
            end: "2% 85%",
            toggleActions: "play none none reverse",
            scrub: 4,
            duration: 1,
            // markers: true
          },
        }
      );
    },
    { scope: content }
  );

  return (
    <div ref={content}>
      <div className="fixednav flex justify-between items-center fixed py-[10px] px-[50px] bottom-[-50px] left-0 right-0 rounded-[20px] max-w-[70%] z-5 ml-auto mr-auto bg-black bg-opacity-70 backdrop-blur-lg 
      md:max-w-[35%] md:min-w-[400px] md:bottom-0 font-normal text-base">
        <a className="text-center no-underline text-white font-light" href="/">
          <div>
            <FontAwesomeIcon icon={faHomeLgAlt} />
          </div>
          <div className="md:block hidden">Home</div>
        </a>
        <a className="text-center no-underline text-white" href="/abouts/">
          <div>
            <FontAwesomeIcon icon={faAddressCard} />
          </div>
          <div className="md:block hidden">About Us</div>
          {/* <div className='md:hidden block'>Us</div> */}
        </a>
        <a
          className="text-center no-underline text-white"
          href="/recruitments/"
        >
          <div>
            <FontAwesomeIcon icon={faHandshakeAngle} />
          </div>

          <div className="md:block hidden">Recruitments</div>
        </a>
        <a className="text-center no-underline text-white" href="/contacts/">
          <div>
            <FontAwesomeIcon icon={faInfo} />
          </div>
          <div className="md:block hidden">Contacts</div>
        </a>
      </div>
    </div>
  );
}

export default FixedNav;
