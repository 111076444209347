import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from '@gsap/react';

import HeaderNav from '../../HeaderFotter/Header/Header.js';
import Fotter from '../../HeaderFotter/Fotter/Fotter.js';
import BlurryCursor from '../../Dependence/Cursor/cursor.jsx';
import FixedNav from '../../HeaderFotter/FixedNav/Fixednav.js';
import { effect_gsap, effect_out, move_year, effect_img } from '../../../gsapHelper/effectGsap';

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
  
  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const PageAboutUs = () => {
  const [isActive, setIsActive] = useState(false);
  gsap.registerPlugin(useGSAP);
  const content = useRef()

  gsap.registerPlugin(ScrollTrigger)
  
  useEffect(() => {
    // Chắc chắn rằng window và document đều sẵn sàng
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      ScrollTrigger.refresh();
    }
  }, []);

  useEffect(() => {
    const imagesLoaded = document.querySelectorAll('img');
    const totalImages = imagesLoaded.length;
    let imagesLoadedCount = 0;
  
    imagesLoaded.forEach(image => {
      if (image.complete) {
        imagesLoadedCount++;
      } else {
        image.onload = () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            // Khởi tạo ScrollTrigger sau khi tất cả hình ảnh đã tải xong
            ScrollTrigger.refresh();
          }
        };
      }
    });
  
    if (imagesLoadedCount === totalImages) {
      ScrollTrigger.refresh();
    }
  }, []);  


  useGSAP(
    () => {
      // Di chuyển khối
      // effect_gsap('.Introduction', 0);
      effect_out('.Introduction', -100);

      effect_gsap('.our-mission', 0);
      effect_out('.our-mission', -100);

      effect_gsap('.team-building', 0);
      effect_out('.team-building', -100);


      // Di chuyển chữ Year
      move_year(".start-2015", ".zone-year-2015", -500)
      move_year(".start-2022", ".zone-year-2022", -2300)
      move_year(".start-current", ".zone-year-current", "-1300")

      // Di chuyển ảnh
      effect_img(".img-establish", 200)
      effect_img(".img1", -200)
      effect_img(".img2", 200, -200)
      effect_img(".img3", -200, -200)
      effect_img(".img4", -200)
      // effect_img(".img5", 200)

      effect_img(".img5", 200)
      effect_img(".img6", 200, -200)
      effect_img(".img7", -200, -200)


    },
    { scope: content }
  );

  return (
    <Container className='content' ref={content}>

      {/* headder */}
      <HeaderNav></HeaderNav>

      {/* Section 1 */}
      <Section className="about-vis opacity-1" >
        <div className=''>
          <div className='flex justify-center contacts'>
            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp m-0 ct-headline ">About VIS Group</h1>
          </div>
          <div>
            <div id="thanh_den" style={{ width: "100%" }}></div>
          </div>

          <div className='flex justify-between gap-5'>
            <div className='h-auto pl-20'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <p className='text-justify'>Founded in 2015, VIS Group has quickly become a leader in e-commerce solutions across the European and American markets, blending creativity with technology to deliver superior products and services. Our experienced team is at the forefront of developing innovative online products and executing impactful marketing campaigns. At VIS Group, we value human connections and place great emphasis on a fair and transparent reward system. We strive to foster a dynamic, creative, and friendly workplace that supports both professional growth and personal development. Our commitment extends beyond business achievements; we are dedicated to enhancing the well-being of each team member and nurturing a positive corporate culture. Join us as we create meaningful value and pursue professional excellence together.</p>
            </div>
          </div>
        </div>
      </Section>

      {/* Section 2 */}
      <Section className='Introduction opacity-1'>
        <div className='md:flex'>
          <div className='md:flex flex-col md:w-5/12 gap-10'>
            <div className='uppercase text-[30px] md:text-[50px] font-bold'>Life at Vis Group:</div>
            <div id='thanh_den' style={{ width: "100%" }}> </div>
            <div> Our team at Vis Group is the driving force behind our success. We are committed to fostering an environment that encourages creativity, collaboration, and continuous learning.</div>
          </div>
          <div className='flex  md:w-9/12 justify-end pt-5'>
            <img src='/Img/AboutUs/office.png' alt='' className='w-full md:w-11/12 rounded-3xl' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
          </div>
        </div>
      </Section>

      {/* Section 3 */}
      <Section className='our-mission opacity-0'>
        <div className='flex'>
          <div className='md:block hidden w-8/12'>
            <img src='/Img/AboutUs/img.jfif' alt='' className='w-11/12 rounded-3xl' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
          </div>
          <div className='flex flex-col w-full gap-5 md:pl-20'>
            <div className='uppercase text-[30px] md:text-[50px] font-bold'>Core Values:</div>
            <div id='thanh_den' style={{ width: "100%" }}> </div>
            <div className='md:hidden w-full flex justify-center'>
              <img src='/Img/AboutUs/img.jfif' alt='' className=' w-8/12 rounded-3xl' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
            </div>
            <div className='w-full'>
              <strong className='pl-5'>•	Creativity:</strong> We cherish and nurture the creative spirit within all of us.<br />
              <strong className='pl-5'>•	Quality:</strong> We commit to the highest standards in every product we deliver.<br />
              <strong className='pl-5'>•	Customer Focus:</strong> We strive to meet and exceed customer expectations in every interaction.<br />
              <strong className='pl-5'>•	Sustainability:</strong> We advocate for practices that contribute to a sustainable future.</div>
          </div>
        </div>
      </Section>

      {/* Section 4 */}
      <Section className="team-building opacity-0" >
        <div className=''>
          <div className='flex justify-center contacts'>
            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp-100 m-0 ct-headline ">Celebrations and Team Building</h1>
          </div>
          {/* <div>
            <div id="thanh_den" style={{ width: "100%" }}></div>
          </div> */}

          <div className='flex justify-between gap-5'>
            <div className='h-auto md:pl-20'>
              <div id="thanh_den" style={{ width: "3px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>

            <div className='w-full'>
              <p className='text-justify'>We believe in celebrating every milestone, whether it's a successful product launch or festive occasions. Our team gatherings and events foster a sense of community and shared purpose.</p>

              {/* Cái này ở trong */}
              <div className='flex gap-10 pb-10'>
                <div className='h-auto pl-10'>
                  <div id="thanh_den" style={{ width: "1px", minWidth: "1px", height: "100%", margin: 0 }}></div>
                </div>

                <div className='w-full flex flex-col justify-end'>
                  {/* Năm 2015 */}
                  <div className='zone-year-2015 w-full flex justify-between pb-10 '>
                    <div className='md:flex hidden items-end start-2015'>
                      <div className='font-bold text-[50px]'>2015</div>
                    </div>

                    {/* Nhánh nhỏ hơn */}
                    <div className='md:w-4/6'>
                      <div className='md:flex justify-between'>
                        <div className='flex items-center md:w-2/12 pb-10' >
                          03/2015<br />VIS Company is establishment
                        </div>
                        <div className='md:w-9/12'>
                          <img src='/Img/AboutUs/establish.jfif' alt='' className='img-establish w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* Năm 2022 */}
                  <div className='zone-year-2022 w-full flex justify-between pb-10 '>
                    <div className='md:flex hidden items-end start-2022'>
                      <div className='font-bold text-[50px]'>2022</div>
                    </div>

                    {/* Nhánh nhỏ hơn */}
                    <div className='md:w-4/6'>
                      <div className='md:flex justify-between pb-10'>
                        <div className='flex items-center pb-10'>
                          03/2022<br />TeamBuilding
                        </div>
                        <div className='md:w-9/12'>
                          <img src='/Img/AboutUs/2022.jpg' alt='' className='img1 w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                          <img src='/Img/AboutUs/team_building.jpg' alt='' className='img2 w-full rounded-3xl' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div>

                      {/* Thangs 6 */}
                      <div className='md:flex justify-between pb-10'>
                        <div className='flex items-center  pb-10' >
                          06/2022<br />Summer
                        </div>
                        <div className='md:w-9/12'>
                          <img src='/Img/AboutUs/6-2022.jpg' alt='' className='img3 w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div>

                      {/* Tháng khác ở đây */}
                      <div className='md:flex justify-between pb-10'>
                        <div className='flex items-center  pb-10' >
                          12/2022<br />Year End Party
                        </div>
                        <div className='md:w-9/12'>
                          <img src='/Img/AboutUs/yep-2022.jpg' alt='' className='img4 w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* Năm 2023*/}
                  <div className='zone-year-current w-full flex justify-between pb-10'>
                    <div className='md:flex hidden items-end start-2022'>
                      <div className='font-bold text-[50px] start-current'>2023</div>
                    </div>

                    {/* Nhánh nhỏ hơn */}
                    <div className='md:w-4/6'>
                      <div className='md:flex justify-between pb-10'>
                        <div className='flex items-center pb-10' >
                          03/2023<br />TET's
                        </div>
                        <div className='md:w-9/12'>
                          <img src='/Img/AboutUs/23-2023.jpg' alt='' className='img5 w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                          <img src='/Img/AboutUs/21-2023.jpg' alt='' className='img6 w-full rounded-3xl' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div>

                      {/* Thangs 6 */}
                      {/* <div className='flex justify-between pb-10'>
                        <div className='flex items-center' >
                          06/2022<br />Summer
                        </div>
                        <div className='w-9/12'>
                          <img src='/Img/AboutUs/6-2022.jpg' alt='' className='img3 w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div> */}

                      {/* Tháng khác ở đây */}
                      <div className='md:flex justify-between pb-10'>
                        <div className='flex items-center pb-10' >
                          12/2023<br />Year End Party
                        </div>
                        <div className='md:w-9/12'>
                          <img src='/Img/bg.jpg' alt='' className='img7 w-full rounded-3xl mb-5' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                        </div>
                      </div>
                    </div>

                  </div>

                  {/* Năm tiếp theo */}
                  <div className='zone-year-2024 w-full flex justify-between pb-10'>
                    <div className='md:flex hidden items-end start-2022'>
                      <div className='font-bold text-[50px] start-2024'>...</div>
                    </div>

                    {/* Nhánh nhỏ hơn */}
                    <div className='w-4/6'>
                      <div className='flex justify-between pb-10'>
                        <div className='flex items-center' >
                          Your future and ours!
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Section>

      {/* Section 5 */}
      <Section className="Corporate-Social opacity-1" >
        <div className=''>
          <div className='flex justify-center contacts'>
            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp-100 m-0 ct-headline ">Corporate Social Responsibility</h1>
          </div>
          <div>
            <div id="thanh_den" style={{ width: "100%" }}></div>
          </div>

          <div className='flex justify-between gap-5'>
            <div className='h-auto pl-20'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <p className='text-justify'>Vis Group is dedicated to making a positive impact on the community and the environment. Our sustainable printing techniques and community outreach programs reflect our commitment to responsible business practices.</p>
            </div>
          </div>
        </div>
      </Section>

      {/* Section 6 */}
      <Section className="Join-Our-Team opacity-1" >
        <div className=''>
          <div className='flex justify-center contacts'>
            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp m-0 ct-headline ">Join Our Team</h1>
          </div>
          <div>
            <div id="thanh_den" style={{ width: "100%" }}></div>
          </div>

          <div className='flex '>
            <div className='flex justify-between gap-5 w-3/6'>
              {/* <div className='h-auto pl-20'>
                <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
              </div> */}
              <div className='w-full'>
                <p className='md:text-justify'>
                  <p className='font-bold uppercase'>Careers at Vis Group:</p>
                  We are always on the lookout for talented individuals who are passionate about design and innovation. If you're ready to contribute to a dynamic team and work on exciting projects, check out our Careers page for current opportunities.<br />
                </p>
              </div>
            </div>

            <div className='h-auto md:mx-20 mx-4'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='flex justify-between gap-5 w-3/6'>
              <div className='w-full'>
                <p className='md:text-justify'>
                  <p className='font-bold uppercase'>Why Choose Vis Group?</p>
                  <strong>•	Creative Freedom: </strong>Engage in projects that stimulate your creativity and professional growth.<br />
                  <strong>•	Dynamic Environment: </strong>Work with a diverse team that values fresh ideas and perspectives.<br />
                  <strong>•	Competitive Benefits: </strong>Enjoy a rewarding package that appreciates your hard work and dedication.</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Section 7 */}
      <Section className='info-overview'>
        <div>
          <div> Phone: +84326239515 </div>
          <div> Address: 15 Nguyen Mong Tuan, Hoa Minh, Lien Chieu, Da Nang </div>
          <div> Page:<a className='no-underline' href='https://www.facebook.com/visstudio.co'> VIS Group</a> </div>
          <br />
          <div id="ct-main-icon">
            <img alt="" src="/Img/icon/amazon.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="/Img/icon/tiktok.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="/Img/icon/etsy.jfif" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="https://www.pinguinweb.de/wp-content/uploads/2022/09/woocommerce.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="https://www.pinguinweb.de/wp-content/uploads/2022/09/oxy.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
          </div>
        </div>
      </Section>

      <Fotter></Fotter>

      <FixedNav />
      <BlurryCursor isActive={isActive} />
    </Container>
  );
};

export default PageAboutUs;