import React, { useRef } from "react";
import styled, { css } from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 18px;
  color: #7d7f78;
  font-weight: 300;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-top: 30px;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const HeaderNavNew = ({onActive, handleScroll }) => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();

  useGSAP(
    () => {
      gsap.from(".effect1", {
        opacity: 0,
        x: -200,
        duration: 3,
        ease: "power3.out",
      });

      gsap.from(".effect2", {
        opacity: 0,
        y: -200,
        duration: 3,
        ease: "power3.out",
      });

      gsap.from(".effect3", {
        opacity: 0,
        width: "1%",
        duration: 3,
        ease: "power3.out",
      });
    },
    { scope: content }
  );

  return (
    <Container className="content" ref={content}>
      <Section
        className="fixed z-20 head-title sm:flex justify-between pt-30"
      >
        <nav className="w-full opacity-100 pt-30">
          <div className="flex items-center justify-between px-4 py-2">
            <a href="/"
            onMouseOver={() => {
              onActive(true);
            }}
            onMouseLeave={() => {
              onActive(false);
            }}
            >
              <div className="flex items-center">
                <div className="logo">
                  <img
                    src="/Img/logo_new.png"
                    alt="Logo"
                    className="h-[36px] w-auto"
                  />
                </div>
                <div className="ml-4 flex flex-col text-[15.9px]">
                  <p className='text-white font-bold p-0 font-["Be Vietnam Pro"]'>
                    VIS
                  </p>
                  <p className="text-white font-bold p-0">GROUP</p>
                </div>
              </div>
            </a>
            <div className="hidden md:block bg-[#161616] bg-opacity-60 backdrop-blur-sm rounded-full border-[1px] border-gray-400 p-4 px-10 font-medium">
              <div className="flex space-x-6">
                <a
                  className="hover:text-white"
                  href="true"
                  onClick={(e) => {
                    e.preventDefault(); // Ngăn chặn hành vi mặc định
                    handleScroll("header"); // Gọi hàm scroll
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  About us
                </a>
                <a
                  className="hover:text-white"
                  href="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("hww");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  How we works
                </a>
                <a
                  className="hover:text-white"
                  href="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("Testimonials");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  Testimonials
                </a>
                <a
                  className="hover:text-white"
                  href="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("Faq");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  FAQs
                </a>
                <a
                  className="hover:text-white"
                  href="true"
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll("Careers");
                  }}
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  Careers
                </a>
              </div>
            </div>
            <a className="ml-4" href="true">
              <button className="bg-[#ddff00] text-black font-bold py-2 px-4 rounded-full">
                <a
                  className="text-center no-underline"
                  href="/recruitments/"
                  onMouseOver={() => {
                    onActive(true);
                  }}
                  onMouseLeave={() => {
                    onActive(false);
                  }}
                >
                  Apply Job
                </a>
              </button>
            </a>
          </div>
        </nav>
      </Section>
    </Container>
  );
};

export default HeaderNavNew;
